import * as React from "react";
import { Link, graphql } from "gatsby";
import Nav from "../components/nav";
import Header from "../components/header";
import Contact from "../components/contact";
import Feature from "../components/feature";

const ConfirmPage = ({ location, data }) => {
  console.log(location);
  //location.search
  let features = data.allContentfulFeatures.edges;
  const btn1 = {
    colour: "orange",
    text: "Join the Slack channel",
    link: "https://join.slack.com/t/alchemyboxworkspace/shared_invite/zt-qvv43zkg-m3HU3YbjV1w_jA~uyxzAMg",
  };
  const btn2 = { colour: "outline", text: "Finish", link: "/" };
  const header_button = [btn1, btn2];
  features = features.reduce(function (arr, feature) {
    arr[feature.node.order] = feature;
    return arr;
  }, []);
  console.log(features);

  return (
    <main className="bg-white">
      <title>Alchemy Box - Welcome to the Beta program</title>
      <Nav colour="white" default_state={location.hash.replace("#", "")} />
      <Header
        title="Welcome to the beta program!"
        colour="blue"
        subtitle={
          <div>
            <p className="mt-2 mb-5">
              Join the slack channel for more information on getting started.
            </p>
            <p className="mb-3">
              An email has also been sent to you with these details
            </p>
          </div>
        }
        buttons={header_button}
        margin_bottom={24}
      />
    </main>
  );
};

export default ConfirmPage;

export const query = graphql`
  query {
    allContentfulFeatures {
      edges {
        node {
          title
          id
          order
          description {
            description
          }
          image {
            title
            gatsbyImageData
          }
        }
      }
    }
  }
`;
